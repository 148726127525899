<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox joinDone">
        <h1 style="text-align: center;font-size:1.6rem">
          {{ $t("sign-in-info-done") }}
        </h1>
        <h3 style="max-width: 100%;font-size:1.2rem;color:#969696;text-align: center;">
          {{ $t("sign-in-info-done-approval") }}
          담당자의 승인이 완료되지 않을 경우 서비스 이용이 어려울 수 있습니다.
          이 점 양해의 말씀을 드립니다.
          <!--{{ $t("sign-in-info-done-email") }} <b> {{ this.email }}</b> {{ $t("sign-in-info-done-send-email")}}-->
          <br />
          <br />
          {{ $t("sign-in-info-done-emergency") }}
        </h3>
        <div class="buttonWrap">
            <button class="point large" @click="onReLogin">{{ $t("btn-re-login") }}</button>
            <!--<button class="point large" v-show="device && workerType">
            {{ $t("59") }}
          </button>-->
        </div>
      </div>
      <!-- <Footer /> -->
    </div>
    <Footer />
  </div>
</template>
<style>
  .buttonWrap > button{background: #0080FF;border: 0;border-radius: 5px;}
</style>
<script>
// import Footer from "@/components/Footer";

export default {
  // components: { Footer },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      device: null,
      workerType: null,
      accountId : null,
    };
  },
  mounted() {
    this.device = this.$route.query.device;
    this.workerType = this.$route.query.workerType;
    this.accountId = this.$route.params.accountId;
  },
  methods: {
    onReLogin(){
      if (this.device == "ControllerApp" || this.device == "ViewApp") {
        window.Android.reLogin("로그인 이동");
        return;
      }
      this.$router.push({path : `/${this.accountId}/login`})
      document.location.href = `/${this.accountId}/login`;
    },
  },

};
</script>
